<template>
  <div>
    <validation-observer ref="createSession">
      <b-form>
        <b-modal
          dialog-class="session-modal-dialog"
          content-class="session-modal-content"
          v-model="sessionModal"
          scrollable
          size="sm"
          ref="createModal"
          @hide="toggleSessionDialog(false)"
        >
          <template #modal-title>
            <strong>إضافة جلسة</strong>
          </template>
          <b-form-group label="اسم الجلسة" label-for="sessionName">
            <validation-provider #default="{ errors }" name="اسم الجلسة" rules="required">
              <b-form-input
                id="sessionName"
                v-model="sessionForm.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="تابع للمادة" label-for="subjectId">
            <validation-provider
              #default="{ errors }"
              name="تابع للمادة"
              rules="required"
            >
              <v-select
                id="subjectId"
                v-model="sessionForm.subjectId"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                :options="subjectsList"
                :reduce="(item) => item.id"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="الأستاذ" label-for="teacherId">
            <validation-provider
              #default="{ errors }"
              name="الأستاذ"
              rules="required"
            >
              <v-select
                id="teacherId"
                v-model="sessionForm.teacherId"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                :options="teachersList"
                :reduce="(item) => item.id"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="الترتيب" label-for="sessionOrder">
            <validation-provider
              #default="{ errors }"
              name="الترتيب"
              rules="required|positive"
            >
              <b-input-group append="#">
                <b-form-input
                  id="sessionOrder"
                  v-model="sessionForm.unitOrder"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
           <b-form-group label="السعر الافتراضي" label-for="price">
            <validation-provider
              #default="{ errors }"
              name="السعر الافتراضي"
              rules="required"
            >
              <b-input-group append="ل.س">
                <b-form-input
                  id="price"
                  v-model="sessionForm.price"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="الوصف" label-for="description">
            <validation-provider #default="{ errors }" name="الوصف" rules="required">
              <b-form-textarea
                id="description"
                v-model="sessionForm.description"
                rows="4"
                :state="errors.length > 1 ? false : null"
              >
              </b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <template #modal-footer>
            <b-form-checkbox 
            id="isFree"
            v-model="sessionForm.isFree"
            class="custom-control-primary mr-auto" 
            name="check-button"
            switch >
            مجانية
            </b-form-checkbox>
          
            <b-button
              size="md"
              type="submit"
              variant="primary"
              @click.prevent="SubmitAddSession()"
            >
              إضافة
            </b-button>
            <b-button
              @click="toggleSessionDialog(false)"
              size="md"
              variant="outline-primary"
            >
              تراجع
            </b-button>
          </template>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<style>
.session-modal-dialog {
  margin: 0 !important;
  max-height: 100vh !important;
  height: 100vh;
}
.session-modal-content {
  max-height: 100vh !important;
  border-radius: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BInputGroup,
  BFormCheckbox
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, positive } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BInputGroup,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  data: () => ({
    sessionModal: false,
    required,
    positive
  }),
  created() {
    localize("ar");
  },
  computed: {
    ...mapGetters(["isSessionDialog", "sessionForm", "subjectsList", "teachersList"]),
  },
  methods: {
    ...mapActions(["toggleSessionDialog", "setExamSession"]),
    SubmitAddSession() {
      this.$refs.createSession.validate().then((success) => {
        if (success) {
          this.setExamSession(this.sessionForm);
          this.$refs.createModal.hide();
          this.$bvToast.toast(`تم إضافة الجلسة ${this.sessionForm.name} بنجاح`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }
      });
    },
  },
  watch: {
    isSessionDialog(newVal) {
      this.sessionModal = newVal;
    },
  },
};
</script>