<template>
  <div>
    <b-row v-if="mainSessionList.length > 0">
      <b-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(session, index) in sessionsActivePage"
        :key="index"
      >
        <b-card img-top class="mb-3 cardShadow mx-auto" no-body>
          <b-card-img
            src="https://picsum.photos/600/300/?image=20"
            class="w-100"
            style="border-radius: 0 !important"
          ></b-card-img>

          <b-badge v-if="session.isFree" class="isFreeBadge" variant="success"
            >مجانية
          </b-badge>
          <b-button-group>
            <b-button
              size="sm"
              variant="btn-flat"
              class="d-flex align-items-center justify-content-center"
            >
              <unicon name="ticket" class="pr-1" fill="#5E5873" width="18"></unicon>
              <strong>({{ session.subscriptionsCount }})</strong>
            </b-button>
            <b-button
              size="sm"
              variant="btn-flat"
              class="d-flex align-items-center justify-content-center"
            >
              <unicon name="bookmark" class="pr-1" fill="#5E5873" width="18"></unicon>
              <strong>({{ session.lessonsCount }})</strong>
            </b-button>
            <b-button
              size="sm"
              variant="btn-flat"
              class="d-flex align-items-center justify-content-center"
            >
              <unicon name="video" class="pr-1" fill="#5E5873" width="18"></unicon>
              <strong>({{ session.videosCount }})</strong>
            </b-button>
          </b-button-group>
          <b-card-header class="py-1">
            <strong>{{ session.name }}</strong>
          </b-card-header>
          <b-card-body class="pt-0">
            <b-card-text class="text-truncate">
              {{ session.subjectName }}
            </b-card-text>
          </b-card-body>

          <b-button
            href="#"
            size="md"
            variant="primary"
            style="border-radius: 0 0 0.428rem 0.428rem !important"
            :to="`/exam-sessions/details/${session.id}`"
          >
            عرض التفاصيل
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <b-pagination
          :value="sessionsFirstPage"
          :total-rows="mainSessionList.length"
          :per-page="sessionPageLength"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) => sessionsPagination(value)"
        >
          <template #prev-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
          <template #next-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row v-else class="justify-content-center mt-2">
      <h4>لا يوجد جلسات متاحة</h4>
    </b-row>
    <examDialog />
  </div>
</template>

<style scoped>
.cardShadow {
  max-width: 20rem;
}
.isFreeBadge {
  position: absolute;
  right: 15px;
  top: 11px;
  padding: 8px;
  opacity: 0.9;
}
</style>

<script>
import store from "@/store";
import examDialog from "./components/examSessionDialog";
import {
  BPagination,
  BCard,
  BCardText,
  BButtonGroup,
  BButton,
  BCardImg,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BBadge,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { getUserData } from "@/auth/utils";
import { Admin } from "@/router";

export default {
  components: {
    examDialog,
    BPagination,
    BCard,
    BCardText,
    BButtonGroup,
    BButton,
    BCardImg,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BBadge,
  },
  data: () => ({
  }),
  mounted() {
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
        isActive: true,
        isbuttonActive: getUserData().role == Admin,
        text: "جلسة جديدة",
        fetchingFunction: this.toggleSessionDialog,
        placeHolder: "ابحث عن جلسة محددة",
        value: "",
        filterFunc: this.filterExamSession,
    });
    this.fetchExamSessions();
    this.getSubjectsList();
    this.getTeachersList();
  },
  computed: {
    ...mapGetters([
      "sessionsActivePage",
      "sessionsFirstPage",
      "sessionPageLength",
      "mainSessionList"
    ]),
  },
  methods: {
    ...mapActions([
      "fetchExamSessions",
      "toggleSessionDialog",
      "getSubjectsList",
      "getTeachersList",
      "sessionsPagination",
      "filterExamSession"
    ]),
  },
};
</script>
